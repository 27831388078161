import React from 'react';

const ContactPage = () => {
  return (
    <div>
      <h1>Contact Details</h1>
      <p>
        Place holder for where we can list resources in the area
        such as snow plow, plumber, cleaner etc... and also our information
      </p>
    </div>
  );
};

export default ContactPage;